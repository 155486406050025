exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-air-quality-monitoring-network-in-the-pacific-islands-js": () => import("./../../../src/pages/air-quality-monitoring-network-in-the-pacific-islands.js" /* webpackChunkName: "component---src-pages-air-quality-monitoring-network-in-the-pacific-islands-js" */),
  "component---src-pages-airvoice-privacy-js": () => import("./../../../src/pages/airvoice-privacy.js" /* webpackChunkName: "component---src-pages-airvoice-privacy-js" */),
  "component---src-pages-communities-js": () => import("./../../../src/pages/communities.js" /* webpackChunkName: "component---src-pages-communities-js" */),
  "component---src-pages-connect-with-us-js": () => import("./../../../src/pages/connect-with-us.js" /* webpackChunkName: "component---src-pages-connect-with-us-js" */),
  "component---src-pages-dust-dispersion-control-solution-for-coal-transloading-terminal-js": () => import("./../../../src/pages/dust-dispersion-control-solution-for-coal-transloading-terminal.js" /* webpackChunkName: "component---src-pages-dust-dispersion-control-solution-for-coal-transloading-terminal-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-how-scientific-monitoring-network-operaties-in-one-of-the-most-polluted-cities-in-the-world-js": () => import("./../../../src/pages/how-scientific-monitoring-network-operaties-in-one-of-the-most-polluted-cities-in-the-world.js" /* webpackChunkName: "component---src-pages-how-scientific-monitoring-network-operaties-in-one-of-the-most-polluted-cities-in-the-world-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-main-page-js": () => import("./../../../src/pages/main-page.js" /* webpackChunkName: "component---src-pages-main-page-js" */),
  "component---src-pages-modeling-js": () => import("./../../../src/pages/modeling.js" /* webpackChunkName: "component---src-pages-modeling-js" */),
  "component---src-pages-news-airvoice-has-verified-air-quality-data-from-airquality-report-and-agreed-on-project-integration-into-the-airvoice-platform-mdx": () => import("./../../../src/pages/news/airvoice-has-verified-air-quality-data-from-airquality.report-and-agreed-on-project-integration-into-the--airvoice-platform.mdx" /* webpackChunkName: "component---src-pages-news-airvoice-has-verified-air-quality-data-from-airquality-report-and-agreed-on-project-integration-into-the-airvoice-platform-mdx" */),
  "component---src-pages-news-how-airvoice-monitors-may-be-used-for-volcanic-eruptions-control-and-notifications-mdx": () => import("./../../../src/pages/news/how-airvoice-monitors-may-be-used-for-volcanic-eruptions-control-and-notifications.mdx" /* webpackChunkName: "component---src-pages-news-how-airvoice-monitors-may-be-used-for-volcanic-eruptions-control-and-notifications-mdx" */),
  "component---src-pages-news-test-mdx": () => import("./../../../src/pages/news/test.mdx" /* webpackChunkName: "component---src-pages-news-test-mdx" */),
  "component---src-pages-news-whoosh-and-airvoice-launched-kick-scooters-with-air-quality-sensors-mdx": () => import("./../../../src/pages/news/whoosh-and-airvoice-launched-kick-scooters-with-air-quality-sensors.mdx" /* webpackChunkName: "component---src-pages-news-whoosh-and-airvoice-launched-kick-scooters-with-air-quality-sensors-mdx" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-smart-city-js": () => import("./../../../src/pages/smart-city.js" /* webpackChunkName: "component---src-pages-smart-city-js" */),
  "component---src-pages-systems-for-ventilation-air-purification-and-decontamination-js": () => import("./../../../src/pages/systems-for-ventilation-air-purification-and-decontamination.js" /* webpackChunkName: "component---src-pages-systems-for-ventilation-air-purification-and-decontamination-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

